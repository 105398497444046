var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{class:_vm.categoryOrTag + ' banner',style:(_vm.cover)},[_c('h1',{staticClass:"banner-title animated fadeInDown"},[_vm._v(_vm._s(_vm.title)+" - "+_vm._s(_vm.name))])]),_c('div',{staticClass:"article-list-wrapper"},[_c('v-row',_vm._l((_vm.articleList),function(item){return _c('v-col',{key:item.id,attrs:{"md":"4","cols":"12"}},[_c('v-card',{staticClass:"animated zoomIn article-item-card"},[_c('div',{staticClass:"article-item-cover"},[_c('router-link',{attrs:{"to":_vm.rootUri + '/article/' + item.id}},[_c('v-img',{staticClass:"on-hover",attrs:{"width":"100%","height":"100%","src":item.articleCover ? item.articleCover : _vm.defaultArticleCover}})],1)],1),_c('div',{staticClass:"article-item-info"},[_c('div',[_c('router-link',{attrs:{"to":_vm.rootUri + '/article/' + item.id}},[_vm._v(" "+_vm._s(item.articleTitle)+" ")])],1),_c('div',{staticStyle:{"margin-top":"0.375rem"}},[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("mdi-clock-outline")]),_vm._v(" "+_vm._s(_vm._f("date")(item.publicTime))+" "),_c('router-link',{staticClass:"float-right",attrs:{"to":_vm.rootUri + '/category/' + item.categoryId}},[_c('v-icon',[_vm._v("mdi-bookmark")]),_vm._v(_vm._s(item.categoryName)+" ")],1)],1)]),_c('v-divider'),_c('div',{staticClass:"tag-wrapper"},_vm._l((item.tagList == null
                ? []
                : item.tagList.split(',').map(e => {
                    let tagArr = e.split('=');
                    return {
                      id: tagArr[0],
                      tagName: tagArr[1]
                    };
                  })),function(tag){return _c('router-link',{key:tag.id,staticClass:"tag-btn",attrs:{"to":_vm.rootUri + '/tag/' + tag.id}},[_vm._v(" "+_vm._s(tag.tagName)+" ")])}),1)],1)],1)}),1),_c('infinite-loading',{on:{"infinite":_vm.infiniteHandler}},[_c('div',{attrs:{"slot":"no-more"},slot:"no-more"})])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }